<template>
  <v-container>
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0"
          large
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Dev settings

            <v-spacer />
          </v-card-title>

          <v-card-text>
            <v-switch
              v-model="devEnabled"
              label="Enabled"
            />

            <v-switch
              v-if="devEnabled"
              v-model="displayAdministration"
              label="Display administration"
            />

            <v-switch
              v-if="devEnabled"
              v-model="showInvalidData"
              label="Enable show invalid data"
            />

            <v-switch
              v-if="devEnabled"
              v-model="showMonthlyStatistics"
              label="Enable monthly statistics"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="devEnabled">
      <v-col>
        <v-card>
          <v-card-title>
            Send Slack messages

            <v-spacer />
          </v-card-title>

          <v-card-text>
            <h3>Test activation message</h3>

            <v-text-field
              v-model="eventId"
              label="eventId"
            />

            <v-btn
              :disabled="sendSlackMessageDisabled"
              :loading="loading"
              @click="sendSlackMessage('newWolkActivation')"
            >
              Send activation message
            </v-btn>
          </v-card-text>

          <v-card-text v-if="lastResponse">
            <pre>{{ lastResponse }}</pre>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="devEnabled">
      <v-col>
        <v-card>
          <v-card-title>
            DB commands

            <v-spacer />
          </v-card-title>

          <v-card-text>
            <v-switch
              v-model="addNewReferencesToEventsEnabled"
              label="Add new references to events enabled"
            />

            <v-btn
              :disabled="loading"
              :loading="loading"
              @click="addNewReferencesToEvents()"
            >
              Add new references to events
            </v-btn>
          </v-card-text>

          <v-card-text>
            <v-switch
              v-model="setFormattedIccidsOnEventsEnabled"
              label="Set formatted iccids on events enabled"
            />

            <v-btn
              :disabled="loading"
              :loading="loading"
              @click="setFormattedIccidsOnEvents()"
            >
              Set formatted iccids on events
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      loading: false,
      eventId: '',
      lastResponse: null,
      addNewReferencesToEventsEnabled: false,
      setFormattedIccidsOnEventsEnabled: false,
      analyticsV2: {
        scope: '',
        scopes: [
          'global',
          'organization',
          'location',
          'sleeveAssignment',
          'board',
        ],
        scopeId: '',
        metric: '',
        metrics: [
          'activations',
          'activeDays',
          'stepsTaken',
          'wornSeconds',
        ],
        fromDate: new Date(Date.now() - 1000 * 60 * 60 * 24 * 10).toISOString().substr(0, 10),
        fromDateModal: false,
        toDate: new Date().toISOString().substr(0, 10),
        toDateModal: false,
        responseData: null,
      },
      breadcrumbs: [
        {
          text: 'Wolk dashboard',
          disabled: false,
          exact: true,
          to: { name: 'home' },
        },
        {
          text: 'Dev settings',
          disabled: true,
          exact: true,
          to: { name: 'devSettings' },
        },
      ],
    };
  },
  computed: {
    devEnabled: {
      get() {
        return this.$store.state.dev.enabled;
      },
      set(value) {
        this.$store.dispatch('dev/setEnabled', value);
      },
    },
    showInvalidData: {
      get() {
        return this.$store.state.dev.showInvalidData;
      },
      set(value) {
        this.$store.dispatch('dev/setShowInvalidData', value);
      },
    },
    showMonthlyStatistics: {
      get() {
        return this.$store.state.dev.showMonthlyStatistics;
      },
      set(value) {
        this.$store.dispatch('dev/setShowMonthlyStatistics', value);
      },
    },
    displayAdministration: {
      get() {
        return this.$store.state.dev.displayAdministration;
      },
      set(value) {
        this.$store.dispatch('dev/setDisplayAdministration', value);
      },
    },
    sendSlackMessageDisabled() {
      return !this.eventId;
    },
  },
  methods: {
    addNewReferencesToEvents() {
      this.loading = true;
      const url = `${config.VUE_APP_API_BASE_URL}/conversions/add-new-references-to-events`;

      const data = {
        from: Date.now() - 1000 * 60 * 60 * 24 * 10,
        to: Date.now(),
        limit: 5000,
        groupSize: 20,
      };

      axios({
        method: 'post',
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        data,
      })
        .then(() => {
          this.$store.commit('app/snackbarText', {
            text: 'Add new references to events DONE.',
            visible: true,
          });

          this.loading = false;

          if (this.addNewReferencesToEventsEnabled) {
            this.addNewReferencesToEvents();
          }
        });
    },
    setFormattedIccidsOnEvents() {
      this.loading = true;
      const url = `${config.VUE_APP_API_BASE_URL}/conversions/set-formatted-iccids-on-events`;

      const data = {
        from: Date.now() - 1000 * 60 * 60 * 24 * 10,
        to: Date.now(),
        limit: 5000,
      };

      axios({
        method: 'post',
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        data,
      })
        .then(() => {
          this.$store.commit('app/snackbarText', {
            text: 'Set formatted iccids on events DONE.',
            visible: true,
          });

          this.loading = false;

          if (this.setFormattedIccidsOnEventsEnabled) {
            this.setFormattedIccidsOnEvents();
          }
        });
    },
    sendSlackMessage(messageType) {
      this.loading = true;
      const url = `${config.VUE_APP_API_BASE_URL}/test-message`;

      const { eventId } = this;
      this.eventId = '';

      axios({
        method: 'post',
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        data: {
          messageType,
          eventId,
        },
      })
        .then((response) => {
          this.$store.commit('app/snackbarText', {
            text: 'Test Slack message sent.',
            visible: true,
          });

          if (response.status && response.data) {
            this.lastResponse = {
              status: response.status,
              data: response.data,
            };
          }
        })
        .catch((error) => {
          console.log(`sendSlackMessage error: ${error}`);

          this.$store.commit('app/snackbarText', {
            text: 'Er is iets fout gegaan.',
            visible: true,
          });

          if (error.response) {
            this.lastResponse = {
              status: error.response.status,
              data: error.response.data,
            };
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
